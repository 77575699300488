import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


const JudulEvent = (props) => {

  // const [eventAktif, setEventAktif] = useState("");

  // useEffect(() => {
  //   getEventAktif();
  // }, []);

  // const getEventAktif = async () => {
  //   //const response = await axios.get("https://server.gentana.id/eventAktif");
  //   const response = await axios.get(`${process.env.REACT_APP_API_URL}/eventAktif`);
    
  //   if (response.data.msg === "TIDAK ADA EVENT AKTIF") {
  //     setEventAktif("");
  //   }
  //   else {
  //     setEventAktif(response.data.event);
  //   }
  // };
  return (
    <><h3>{props.judulEvent}</h3></>
  )
}

export default JudulEvent