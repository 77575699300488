import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

const FormInputKehadiran = (props) => {
  const [validated, setValidated] = useState(false);
  const [eventAktif, setEventAktif] = useState([]);
  const [daerah, setDaerah] = useState([]);
  const [desa, setDesa] = useState([]);
  const [kelompok, setKelompok] = useState([]);
  const [jenjang, setJenjang] = useState([]);
  const [pesertaType, setPesertaType] = useState([]);
  const [jenisKelamin, setJenisKelamin] = useState([]);
  const [eventKehadiran, setEventKehadiran] = useState("");
  const [namaKehadiran, setNamaKehadiran] = useState("");
  const [daerahKehadiran, setDaerahKehadiran] = useState("");
  const [desaKehadiran, setDesaKehadiran] = useState("");
  const [kelompokKehadiran, setKelompokKehadiran] = useState("");
  const [jenisKelaminKehadiran, setJenisKelaminKehadiran] = useState("");
  const [jenjangKehadiran, setJenjangKehadiran] = useState("");
  const [pesertaTypeKehadiran, setPesertaTypeKehadiran] = useState("");

  const [jenjangAll, setJenjangAll] = useState([]);
  const [pesertaTypeAll, setPesertaTypeAll] = useState([]);
  const [jenisKelaminAll, setJenisKelaminAll] = useState(["PRIA", "WANITA"]);

  const [logs, setLogs] = useState("");

  useEffect(() => {
    Promise.all([getJenjang(), getPesertaType(), getDaerah()]).then(value => getEventAktif(value));
  }, []);


  const getDaerah = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/daerah`);

    for (var i = 0; i < response.data.length; i++)if (response.data[i].daerah === "Jakarta Selatan 2") {
      setDaerah([response.data[i]]);
      setDaerahKehadiran(response.data[i].id);
      getDesa(response.data[i].id);
    }
  };

  const getDesa = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/desaByDaerah/${id}`);
    setDesa(response.data);
  };

  const getKelompok = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/kelompokByDesa/${id}`);
    setKelompok(response.data);
  };

  const getPesertaType = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/pesertaType`);
    setPesertaType(response.data);
    setPesertaTypeAll(response.data);
    return response.data;
  };

  const getJenjang = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/jenjang`);
    setJenjang(response.data);
    setJenjangAll(response.data);
    return response.data;
  };

  const getEventAktif = async (data) => {

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/eventAktif`);
    console.log(response.data.msg);
    if (response.data.msg === "TIDAK ADA EVENT AKTIF") {
      setEventAktif([]);
      props.update("noEvent");
    }
    else {
      if (response.data.length > 1) response.data.unshift({ id: "", event: "" });
      else {
        setEventKehadiran(response.data[0].id);
        getOneEvent(response.data[0].id, data);
        props.judul(response.data[0].event);
      }
      setEventAktif(response.data);
    }
  };

  const getOneEvent = async (id, data) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/event/${id}`);
      var config = JSON.parse(response.data.config);
      var configJenisKelamin = config.jenisKelamin;
      var configJenjang = config.jenjang;
      var configPesertaType = config.pesertaType;

      var arrayJenisKelamin = ["PRIA", "WANITA"];

      var listJenisKelamin = [];
      var listJenjang = [];
      var listPesertaType = [];

      setNamaKehadiran("");
      setDesaKehadiran("");
      setKelompokKehadiran("");
      setJenisKelaminKehadiran("");
      setJenjangKehadiran("");
      setPesertaTypeKehadiran("");

      setJenisKelamin([]);
      setJenjang([]);
      setPesertaType([]);

      if (data) {
        for (var i = 0; i < configJenjang.length; i++) {
          if (configJenjang[i].show) {
            for (var j = 0; j < data[0].length; j++) {
              if (data[0][j].id === configJenjang[i].id) listJenjang.push(data[0][j]);
            }
          }
        }

        for (var i = 0; i < configPesertaType.length; i++) {
          if (configPesertaType[i].show) {
            for (var j = 0; j < data[1].length; j++) {
              if (data[1][j].id === configPesertaType[i].id) listPesertaType.push(data[1][j]);
            }
          }
        }

        for (var i = 0; i < configJenisKelamin.length; i++) {
          if (configJenisKelamin[i].show) {
            for (var j = 0; j < arrayJenisKelamin.length; j++) {
              if (arrayJenisKelamin[j] === configJenisKelamin[i].id) listJenisKelamin.push(arrayJenisKelamin[j]);
            }
          }
        }
      }

      else {

        for (var i = 0; i < configJenjang.length; i++) {
          if (configJenjang[i].show) {
            for (var j = 0; j < jenjangAll.length; j++) {
              if (jenjangAll[j].id === configJenjang[i].id) listJenjang.push(jenjangAll[j]);
            }
          }
        }

        for (var i = 0; i < configPesertaType.length; i++) {
          if (configPesertaType[i].show) {
            for (var j = 0; j < pesertaTypeAll.length; j++) {
              if (pesertaTypeAll[j].id === configPesertaType[i].id) listPesertaType.push(pesertaTypeAll[j]);
            }
          }
        }

        for (var i = 0; i < configJenisKelamin.length; i++) {
          if (configJenisKelamin[i].show) {
            for (var j = 0; j < jenisKelaminAll.length; j++) {
              if (jenisKelaminAll[j] === configJenisKelamin[i].id) listJenisKelamin.push(jenisKelaminAll[j]);
            }
          }
        }
      }

      if (listJenisKelamin.length === 1) setJenisKelaminKehadiran(listJenisKelamin[0]);
      if (listPesertaType.length === 1) setPesertaTypeKehadiran(listPesertaType[0].id);
      if (listJenjang.length === 1) setJenjangKehadiran(listJenjang[0].id);

      setJenjang([...listJenjang]);
      setPesertaType([...listPesertaType]);
      setJenisKelamin([...listJenisKelamin]);

    } catch (error) {
      props.update("error");
      if (error.response) {

      }
    }
  };


  const submitKehadiran = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      event.preventDefault();
      props.update("loading");
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/kehadiran`, {
          eventId: eventKehadiran,
          nama: namaKehadiran,
          daerahId: daerahKehadiran,
          desaId: desaKehadiran,
          kelompokId: kelompokKehadiran,
          jenisKelamin: jenisKelaminKehadiran,
          jenjangKehadiranId: jenjangKehadiran,
          metodeAbsen: "WEB PESERTA",
          pesertaType: pesertaTypeKehadiran
        });
        setNamaKehadiran("");
        setDesaKehadiran("");
        setKelompokKehadiran("");
        setJenisKelaminKehadiran("");
        setJenjangKehadiran("");
        setEventKehadiran("");
        setPesertaType("");

        setValidated(false);
        props.update("berhasil");
        props.judul("");
      } catch (error) {
        props.update("error");
        if (error.response) {
          //props.setMsg(error.response);
        }
      }
    }
  };

  return (
    <>
      <div id="inputForm" className="pages mt-3">
        <Form noValidate validated={validated} onSubmit={submitKehadiran}>
          <div className="card-body">
            <div className="col-sm-12 offset-sm-0 col-lg-8 offset-lg-2 ">
              <div className="p-4 mb-3 shadowBox">
                <h5>Amal Solih Input Data Dengan Benar</h5>

                <Form.Group as={Col} md="12" xl="12" controlId="validationEvent">
                  <Form.Label>Event/Kegiatan</Form.Label>
                  <Form.Select aria-label="Default select example"
                    required
                    id="eventPeserta"
                    onChange={(e) => {
                      setEventKehadiran(e.target.value);
                      getOneEvent(e.target.value);
                      props.judul(e.target.selectedOptions[0].text);

                    }}
                    value={eventKehadiran}
                  >
                    {
                      eventAktif.length > 1 ? eventAktif.map((item, i) => {
                        return i > 0 ? <option value={item.id}>{item.event}</option> : <option value="" selected disabled></option>
                      }) : eventAktif.map((item, i) => { return <option value={item.id}>{item.event}</option> })
                      // })
                      // eventAktif.length > 1 ? eventAktif.map((item, i) => {
                      //   return <option value={item.id}>{item.event}</option>
                      // }) : eventAktif.map((item, i) => {
                      //   return <option value={item.id}>{item.event}</option>
                      // })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Event Kegiatan
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" xl="12" controlId="validationNama">
                  <Form.Label>Nama</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan Nama"
                    value={namaKehadiran}
                    onChange={(e) => setNamaKehadiran(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Harap Masukkan Nama
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" xl="12" controlId="validationJenisKelamin">
                  <Form.Label>Jenis Kelamin</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) => setJenisKelaminKehadiran(e.target.value)} value={jenisKelaminKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      jenisKelamin.map((item, i) => {
                        return <option value={item}>{item}</option>
                      })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Jenis Kelamin
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" xl="12" controlId="validationDaerah">
                  <Form.Label>Daerah</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) =>{setDaerahKehadiran(e.target.value); getDesa(e.target.value)} } id="daerahPeserta" value={daerahKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      daerah.map(opt => (
                        <option value={opt.id}>{opt.daerah}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Daerah
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="12" xl="12" controlId="validationDesa">
                  <Form.Label>Desa</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) => { setDesaKehadiran(e.target.value); getKelompok(e.target.value) }} id="desaPeserta" value={desaKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      desa.map((item, i) => {
                        if (item.desa === "Daerah") return "";
                        else return <option value={item.id}>{item.desa}</option>
                      })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Desa
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" xl="12" controlId="validationKelompok">
                  <Form.Label>Kelompok</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) => setKelompokKehadiran(e.target.value)} value={kelompokKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      kelompok.map(opt => (
                        <option value={opt.id}>{opt.kelompok}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Kelompok
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" xl="12" controlId="validationJenjang">
                  <Form.Label>Jenjang</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) => setJenjangKehadiran(e.target.value)} value={jenjangKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      jenjang.map(opt => (
                        <option value={opt.id}>{opt.jenjang}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Jenjang
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" xl="12" controlId="validationPesertaType">
                  <Form.Label>Jenis Peserta</Form.Label>
                  <Form.Select aria-label="Default select example" required onChange={(e) => setPesertaTypeKehadiran(e.target.value)} value={pesertaTypeKehadiran}>
                    <option value="" selected disabled></option>
                    {
                      pesertaType.map(opt => (
                        <option value={opt.id}>{opt.pesertaType}</option>
                      ))
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Harap Pilih Jenis Peserta
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="col-xl-12 col-md-12 mb-3 mt-3">
                  <button type="submit" id="btnSubmit" className="btn btn-primary"><i className='bx bx-save'></i> Submit</button>
                </div>
                {/* <p>{logs}</p> */}
              </div>
            </div>
          </div>
        </Form>
      </div>

    </>
  )
}

export default FormInputKehadiran